export function EN_JSON(): any {
  return en;
}
const en = {
  'buttons': {
    'cancel': 'Cancel',
    'confirm': 'Confirm',
    'continue': 'Continue',
    'lang-cancel': '取消',
    'lang-ok': '確定',
    'language': 'Language',
    'no': 'No',
    'ok': 'OK',
    'yes': 'Yes'
  },
  'dialog': {
    'select-table': {
      'invalid-input': 'Invalid input'
    }
  },
  'drinks': {
    'cold-drink': 'Cold Drink',
    'hot-drink': 'Hot Drink',
    'soup': 'Soup',
    'special-drink': 'Special Drink'
  },
  'global': {
    'HK': 'Hong Kong',
    'add-toast-msg': 'Item is added',
    'add-reward-msg': 'Reward is added',
    'additional-msg-hkday': 'Please collect your meal in {{time}} minutes from {{store}} ({{branch}} Store)',
    'back': 'Back',
    'bk-pos-error-message': 'Sorry, the Postal Code is not served by delivery service.',
    'breakfast-close': 'Moblie order service for breakfast is suspended, please try again later.',
    'checkout': 'Check Out',
    'checkout-pay-later': 'Check Out',
    'dinner-close': 'Moblie order service for dinner is suspended.',
    'done': 'DONE',
    'logout-remind': 'Your cart will be cleared after logout. Are you sure?',
    'eat-now': 'Prepare Now',
    'female': 'Female',
    'home': 'Home',
    'item-added': 'Item is added',
    'items': 'item(s)',
    'kiosk-offline-message': 'The App ordering service is currently not available. Please try again later.',
    'lang': 'English',
    'lunch-close': 'Moblie order service for lunch is suspended, please try again later.',
    'male': 'Male',
    'max-delivery-message': 'The delivery total is at most ',
    'max-delivery-message-bk': 'Your order is above $100, Please email order to atyourservice@burgerking.com.sg for processing as an advance order',
    'max-item-message': 'Selected items have exceeded the order limit',
    'min-delivery-message': 'The delivery total is at least ',
    'min-delivery-message-bk': 'Order has to be above $15 for delivery. Do add on a couple more items.',
    'minutes': 'mins',
    'minutes-later': ' Minutes later',
    'next': 'Next',
    'next-page': 'Next',
    'no-menu': 'No items are currently available',
    'no-of-items': ' ITEM(S)',
    'no-service-message': 'The mobile ordering service is not available currently.',
    'no-store-message': 'No stores available currently',
    'noEnoughPoints': 'No Enough Points',
    'order-first': 'Please Place Order First',
    'order-warning-msg': 'Please add items',
    'other': 'Prefer not to say',
    'payment-method': 'Choose your payment method',
    'pick-up-charge': 'Pick up Charge',
    'pick-up-time-invalid': 'Pickup time is invalid. Please select another pickup time',
    'please-select': 'Select',
    'please-select-alert-title': 'Select pick up time',
    'please-select-max': 'Select Max',
    'powered-by': 'Powered by Aigens',
    'previous': 'Back',
    'quantity': 'Qty',
    'total-quantity': 'Total Qty',
    'ready-in': 'Ready In',
    'select': 'Select',
    'step1': 'Step ',
    'step2': '',
    'store-closed-message': ' is closed.',
    'table-warning-msg': 'Please enter table number',
    'tea-time-close': 'Moblie order service for tea is suspended, please try again later.',
    'to-start': 'START YOUR ORDER',
    'up': 'Up',
    'wrong-email-message': 'Please enter a correct email address',
    'wrong-phone-message': 'Please enter a correct mobile number',
    'wrong-first-name-message': 'Please enter your first name',
    'wrong-last-name-message': 'Please enter your last name',
    'wrong-time': 'Current session expired.  Please reload the website.  (or the time set in your device is incorrect; please reset.)',
    'mr': 'Mr.',
    'mrs': 'Mrs.',
    'ms': 'Ms.',
    'miss': 'Miss',
    'customerName': 'Customer Name',
    'cardType': 'Card Type',
    'food-time-out': 'The selected food has timed out, please re-select',
    'timeZone-error': 'Your mobile phone is not set to Hong Kong Standard Time (GMT+8). Please fix this before attempting to place an order.',
    'select-restaurant': 'Select restaurant',
    'hkaaTitle': 'Hong Kong International Airport'
  },
  'pages': {
    'coupon-list': {
      'select-coupon': 'Select e-Coupon',
      'coupon-reminder': 'Use of e-Coupon(s) indicates that you have read and agreed to the Terms & Conditions of the e-Coupon(s)',
      'no-coupon-reminder': 'You don\'t have any coupon.',
      'tnc': 'Terms & Conditions',
      'qty': 'Total Quantity:',
      'valid-until': 'Valid Until:',
      'coupon': 'e-Coupon'
    },
    'base': {
      'missing-tnc': 'Please add Terms and Conditions',
      'order-time-out': 'Order Timed Out',
      'time-out-message': 'Your items will be removed.'
    },
    'openrice': {
      'title': 'Title',
      'CCCode': 'Country / Region Code',
      'visa': 'Visa',
      'master': 'Master',
      'amex': 'American Express',
      'union': 'UnionPay',
      'out-of-stock' : 'Some items are out of stock',
      'marketing-explain': 'Subscribe to our latest news to receive exclusive promotions and offers from us, straight to your mailbox!',
      'marketing': 'I would like to receive discount coupons, offers and latest news regarding HKIA, and agree that my personal data may be used by the Authority for these purposes.',
      'no-credit-card': 'Please select credit card type first'
    },
    'page-byod-brand-splash': {
      'faq': 'FAQ'
    },
    'profileModal': {
      'member-profile': 'Member Profile',
      'member-number': 'Member Number',
      'name': 'Name',
      'mobile': 'Mobile Number',
      'email': 'Email Address',
      'language': 'Preferred Communication Language',
      'birth': 'Date of Birth',
      'area': 'Area of Residence',
      'preferred-communication-language': 'Preferred Communication Language',
      'company': 'Company Name',
      'companySubTitle': 'Airport Staff Information',
      'referral-code': 'Referral Code',
      'certificate-number': 'ARA Permit / Hong Kong Crew Member Certificate Number',
      'expiry-date': 'Expiry Date',
      'staff-tatus': 'Staff Status',
      'approved': 'Approved',
      'toBeExpired': 'Expired (Please renew as soon as possible)',
      'pending': 'Pending',
      'declined': 'Declined',
      'expired': 'Expired'
    },
    'brand-store-list': {
      'alert-title': 'The cart will be cleared. \n Do you want to leave this page?',
      'close-message': 'Restaurant Closed',
      'dinein-only': 'Dine-in Only',
      'opening-hours': 'Opening Hours',
      'takeaway-only': 'Take-away Only',
      'alert-title-hk': 'Remove items',
      'alert-subtitle-hk': 'You items will be removed, \n are you sure?',
      'alert-nobtn-hk': 'No',
      'alert-removebtn-hk': 'Remove'
    },
    'byod-summary': {
      'discount': 'Discount',
      'no': 'No',
      'orderFailure': 'Please contact our staff for assistance',
      'paidWGpay': 'Paid with Google Pay',
      'payHere': 'Pay Here',
      'printer-disconnect-message': 'Printer disconnected',
      'qrcode-message-one': 'Please scan the below order QR code at designated counter.',
      'qrcode-message-two': 'Please pick up your meal within 15 minutes right after the completion of your payment. Otherwise, your order will be cancelled and no refund will be entertained.',
      'reverse-order-button': 'reverse order',
      'service-charge': 'Prices are subject to 10% service charge and prevailing GST.',
      'subtitle': 'Orders',
      'survey1': 'Hi👋, is everything alright?',
      'survey2': 'Tell us about your experience so that we could serve you better next time',
      'table': 'T',
      'tables': 'Table No.',
      'title': 'Order History',
      'yes': 'Sure'
    },
    'cart': {
      'member-send-order': 'Checkout as member to earn points or enjoy discounts',
      'guest-send-order': 'Place Order as Guest',
      'add': 'Duplicate',
      'apply': 'Apply',
      'changeReward': 'Change',
      'confirm': 'Orders cannot be modified or cancelled once confirmed.',
      'continue-order': 'Continue Order',
      'delete': 'Delete',
      'delivery-message': 'Preparation Time',
      'delivery-time': 'Delivery Time',
      'discount-code-fail': 'Invalid promo code',
      'discount-code-ok': 'Promo code applied',
      'done': 'DONE',
      'edit': 'Edit',
      'edit-cap': 'EDIT',
      'input-discount-code': 'Add Promotion Code',
      'last-update-time': 'Last update',
      'miyo': 'Have it your way',
      'myReward': 'My Rewards!',
      'order-more': 'More',
      'ordered-time': 'Order created at : ',
      'payment-no-supported': 'Online payment is not currently supported on this browser. Please use Safari / Chrome',
      'pickUp-time': 'Pick-Up Time',
      'please-select': 'Please select Pickup Time',
      'save-order': 'Save Order',
      'selected-1': ' ',
      'selected-2': 'Item(s)',
      'selectedReward': 'Selected Reward',
      'selectReward': 'Use Reward！',
      'send-order': 'Place Order',
      'send-order-pay-later': 'Place Order',
      'special-request': 'Special Request',
      'subtotal': 'Subtotal',
      'tax-included': ' (Tax Included)',
      'time-limit-1': 'We are trying our best in preparing your food, please try again in',
      'time-limit-2': '.',
      'title': 'Cart',
      'total': 'Total',
      'up-selling-message': 'You may also like...',
      'use-reward': 'Hey! Do you want to use your reward today?',
      'useReard': 'Use Reward!',
      'wechat-missing-openid': 'Missing OpenID, Please scan the QRcode with Wechat again',
      'wechat-no-supported': 'Want to use Wechat Pay? Please scan the QRcode with Wechat again',
      'your-order': 'Orders',
      'pickup-now': 'Now',
      'no-payment-error': 'System error, unable to find payment method, please contact the counter',
      'process-to-payment': 'Process to payment'
    },
    'credit-card-payment': {
      'credit-card': 'Credit or debit card',
      'credit-card-info': 'Payment Details:',
      'credit-card-number': 'Card Number',
      'cvv': 'Security Code',
      'month': 'MM',
      'receiver-info': 'Your Contact Details',
      'year': 'YY'
    },
    'app-DineinOrTakeawayPage': {
      'nextDay': 'Next day',
      'multi-orders-suggestion': 'You may select food from multiple restaurants at once'
    },
    'freeGift': {
      'checkNewReward': 'Discover your new rewards',
      'rewardUnlocked': 'Reward Unlocked!'
    },
    'item-grid': {
      'add': 'Add to Cart',
      'order': 'Order',
      'soldout': 'Sold Out'
    },
    'item-select': {
      'chooseAtMost': 'Choose {{max}} (max)',
      'chooseTo': 'Choose {{min}} to {{max}}',
      'chooseUp': 'Choose up to {{min}}',
      'cold-drink': 'Cold Drink',
      'confirm': 'Confirm',
      'hot-drink': 'Hot Drink',
      'no-need': 'No Need',
      'optional': 'Optional',
      'others-item': 'Others',
      'select-exact-no-of-items': '* Please select {count} item(s)',
      'select-items': 'Choose {{count}}',
      'select-no-of-items': '* Please select {min} to {max} items',
      'soft-drink': 'Soft Drink',
      'soup': 'Soup',
      'special-drink': 'Special Drink'
    },
    'login': {
      'createAcct': 'Create Account',
      'email': 'Email',
      'label-title-1': 'Login with Your ',
      'label-title-2': '',
      'login': 'Login',
      'memberId': 'Member Id',
      'no-account': 'Don\'t have an Account?',
      'password': 'Password',
      'passwordEmpty': 'Please enter your password',
      'phone': 'Phone',
      'register': 'Register Now',
      'reset-password': 'Forgot Password',
      'social-media-title': 'Social Media Login',
      'storellet-title': 'Login with Your Phone Number',
      'title': 'Login with Your Email / Phone Number',
      'username': 'Username',
      'username-placeholder': 'Email/Phone(with country code)',
      'usernameEmpty': 'Please enter your username',
      'incomplete-profile': 'Please complete the profile',
      'reset-password-success': 'Reset Password Successful'
    },
    'member-reward': {
      'apply-coupon': 'Apply coupon during checkout',
      'contunueOrdering': 'Contunue Ordering',
      'how-to-use': 'How to use',
      'logged': 'You have successfully logged in',
      'points-accu': 'Earned Points',
      'profile': 'Profile',
      'recent-promotion': 'Recent Promotion',
      'redeem': 'Redeem Now',
      'reward-available': 'Reward Available',
      'valid-from': 'Valid from',
      'empty-mem': 'More promotions is yet to come',
      'stamps': 'Stamps',
      'stamp-rule': 'Get one stamp of ordering ONE Ramen in every purchase.'
    },
    'menu-list': {
      'menu-fab': 'menu-en',
      'title': 'Menu Management'
    },
    'modifier-item-select': {
      'require': 'Required',
      'title': 'Special Request',
      'modify': 'Modify'
    },
    'multi-order-history': {
      'refresh': 'Refreshing...'
    },
    'multi-order-cart-page': {
      'order-countdown': 'Items will expire in {{minutes}} minutes(s) {{seconds}} seconds'
    },
    'order-status': {
      'fail-message-one': 'Payment Failed',
      'fail-message-two': 'Please ask our staff for assistance',
      'fail-notification': 'Order is cancelled',
      'sending-to-pos-notification': 'Sending to POS',
      'success-message-one': 'Order Success',
      'success-message-three': 'View your order under \'My Order\'',
      'success-message-two': 'Please pick up your order at the designated restaurant once the order is ready for pickup',
      'call': 'You may also call the customer hotline ',
      'call-message': ' to check your order status (Business Hours: 09:00 to 01:00)'
    },
    'order-status-byod': {
      'back': 'Order History',
      'back-to-home': 'Back',
      'message-two': 'Present this QR code to staff for payment',
      'pending-1': 'Sorry!Your Order is pending.',
      'pending-2': 'You can track the order in the',
      'pending-3': 'Order Summary',
      'pending-4': '',
      'received-1': 'Your order has been confirmed.',
      'received-2': 'You can track the order in the',
      'received-3': 'Order Summary',
      'received-4': '',
      'sending-1': 'Please Wait!',
      'sending-2': 'Your order has been confirmed.',
      'sending-3': 'Waiting for the response',
      'summary': 'Order',
      'title': 'Order Status'
    },
    'order-status-prekiosk': {
      'alert-message-one': '請於',
      'alert-message-two': '前往所選定分店的自助售票機付款系統及取單。若逾時未取，請閣下重新輸入訂單，謝謝！',
      'alert-title': '成功訂餐!',
      'back': 'Back',
      'expire-time': 'Expire Time',
      'items': ' item(s)',
      'message-four': '',
      'message-one': 'Order placed',
      'message-three': 'Please scan QR code to settle payment at \'Self-payment Kiosk\' of designated branch before',
      'message-two': 'Please scan the QR Code at express kiosk for payment',
      'method': 'Method',
      'order-detail': '訂單資料',
      'order-grand-total': 'Order Total',
      'store': 'Place',
      'store-address': 'Address'
    },
    'payment': {
      'alipay-check-out': 'Check out with Alipay',
      'card-number': 'CardNumber',
      'check-out': 'CHECKOUT',
      'email': 'Email',
      'email-optional': 'Email (optional)',
      'email-required': 'Email (required)',
      'expires': 'Expires',
      'message-one': 'You will receive a confirmation email after payment',
      'message-two': 'Also you will have a receipt where you could keep tracking your order',
      'mpgs-error': 'Card number invalid or missing.',
      'my-card': 'My Card',
      'phone': 'Mobile Number',
      'phone-optional': 'Phone number (optional)',
      'phone-required': 'Phone number (required)',
      'please-enter-email': 'Please enter email',
      'security-message-1': 'Secured payment service provided by',
      'security-message-2': '.',
      'special-message': 'This online merchant is located in Hong Kong',
      'title': 'Payment',
      'wechat-check-out': 'Check out with Wechat pay',
      'wechat-pay-total': 'Payment Total',
      'no-refund-after-payment': 'No refund after payment',
      'alipay-wechat-reminder': 'Real-name authentication is required for Alipay / WeChat Pay'
    },
    'pre-order': {
      'item-saved': 'List of item(s) has been stored successfully',
      'order-saved': 'Order Saved',
      'page1': './assets/images/preOrder/preIns1_en.jpg',
      'page2': './assets/images/preOrder/preIns2_en.jpg',
      'saved-order-found': 'Saved order found',
      'saved-order-msg': 'Saved cuisines have been added to cart successfully! Go cart and checkout.',
      'seated-action': 'Seated? Enter Verification Code here',
      'go-cart': 'Go Cart'
    },
    'profile': {
      'alert-title': 'Do you want to logout?',
      'complete-profile': 'Please Complete Your Profile',
      'date-of-birth': 'Date of birth',
      'edit': 'Edit',
      'email': 'Email',
      'first-name': 'First Name',
      'gender': 'Gender',
      'last-name': 'Last Name',
      'log-out': 'Log out',
      'phone': 'Phone',
      'profile': 'Profile',
      'reset-password': 'Reset Password',
      'reset-password-success': 'Reset Password Successful',
      'save': 'Save',
      'profile-updated': 'Profile Updated'
    },
    'redirect': {
      'open-wechat': 'Open Wechat',
      'use-wechat': 'Please open the website in Wechat'
    },
    'registration': {
      'alert-title': 'Do you want to leave?',
      'confirm-password': 'Confirm Password',
      'create-message': 'CREATE ACCOUNT',
      'DOB': 'DOB',
      'email': 'Email',
      'gender': 'Gender',
      'message': 'One more step. Endless rewards.',
      'name': 'Name',
      'password': 'Password',
      'first-name': 'First Name',
      'last-name': 'Last Name',
      'password-rule': 'Min 8 characters, at least 1 letter',
      'phone': 'Phone Number',
      'title': 'Registration',
      'required': 'required'
    },
    'reset-password': {
      'confirm-password': 'Confirm Password',
      'count-down-message-one': 'Re-Send code in ',
      'count-down-message-two': ' seconds',
      'email': 'Email',
      'email-receive-code-message': 'Enter Email and get the Reset Passcode',
      'input-code-message-one': 'Please enter the 4-digit code sent to',
      'input-code-message-two': '',
      'old-password': 'Old Password',
      'password': 'Password',
      'phone': 'Phone',
      'phone-receive-code-message': 'Enter Phone Number and get the Reset Passcode via SMS',
      're-send-code-message': 'Re-Send code',
      'receive-email-message': 'Enter Email to get the Reset Passcode',
      'receive-message': 'Enter Email / Phone Number to get the Reset Passcode',
      'reset-password-message': 'Reset Your Password',
      'submit': 'Submit'
    },
    'reward-list': {
      'conditions': 'Conditions',
      'my-coupons': 'My Coupons',
      'terms': 'Terms'
    },
    'saved-order-list': {
      'direction-text': 'Please choose one of the ways to complete the order after being seated',
      'input-code': 'Input table code',
      'scan-code': 'Scan QR code'
    },
    'scan-qrcode': {
      'error-message-default': 'Cannot read any QRcode, Please scan again',
      'error-message-nocamera': 'Cannot open camera',
      'error-message-notcompatible': 'Browser is not compatible, please input table code manually',
      'error-message-timeout': 'Scan time out!',
      'loading-message-reading': 'Reading Qrcode',
      'loading-message-scanning': 'Scanning...',
      'loading-message-starting': 'Starting camera'
    },
    'search-item': {
      'recent-search': 'Recent Search',
      'search': 'Search',
      'show': 'Show',
      'results': 'results',
      'search-result': 'Search results'
    },
    'select-table': {
      'please-select': 'Please Select',
      'room': 'Room',
      'select-location': 'Select Location',
      'table': 'Table',
      'table-number': 'Table Number'
    },
    'set-select': {
      'click-to-start': 'Click here to Start',
      'confirm-order': 'Add to Cart',
      'edit-order': 'Done',
      'mandatory': 'Mandatory',
      'miyo': 'Have it your way',
      'no-need': 'No Need',
      'not-yet-completed': 'Incomplete',
      'optional': 'Optional',
      'picked': 'Picked',
      'please-select': 'Please Select',
      'select-error': 'Please select all options',
      'serve-with': 'served with',
      'special-request': 'Special Request',
      'complete-first': 'Complete your set at the above'
    },
    'splash': {
      'welcome': 'Welcome',
      'address': 'Store',
      'continue': 'Continue Order',
      'reorder': 'Would you like to continue your previous order?',
      'continue-ask': 'Order is already placed. Do you want to add more items?',
      'date': '- Date -',
      'dine-in': 'Dine In',
      'enterPasscode': 'Please Enter Passcode',
      'enterPasscode-hkbh': '*You can find the code printed in the hand strap ',
      'enterPasscode-hkbh1': 'As shown above, ',
      'enterPasscode-hkbh2': 'enter the code printed on patient\'s hand strap (Including Hyphen)',
      'enterPasscode-hkbh3': 'e.g. A301-1 or A101',
      'errorPasscode': 'Invalid Passcode, Please Enter again',
      'general-table-locked': 'Ordering time expired.',
      'login': 'Login',
      'member': 'Member',
      'mobile-only': 'Please use mobile phone to take order',
      'prompt-dine-mode': 'PLEASE SELECT',
      'session': '- Session -',
      'staff': '- Staff -',
      'start': 'Start Order',
      'store-close': 'Store Closed',
      'summary': 'My Order',
      'table': 'Table',
      'table-locked': 'Table is locked by another phone.',
      'takeout': 'Takeaway',
      'no-cat-error': 'Mobile ordering service is temporary suspended. Please try again later.',
      'input-seats': 'How many people for this table?',
      'input-seats-subtitle': 'For any changes, please find our staff for assistance',
      'seats-placeholder': '1-20',
      'seats-hint': 'Invalid number entered',
      'updated-seats': 'updated seats',
      'food-order-service': 'Food Order Service'
    },
    'summary-list': {
      "payment-not-confirmed": "Order is not completed",
      'current-order': 'Current Order',
      'detail': 'DETAILS',
      'lines1': 'PICK UP NOW?',
      'lines2': 'Slide the button and show it to shop staff to confirm pick up',
      'lines3': '*Unpicked order will be expired after 30 minutes.',
      'lines4': 'Enjoy Your Meal !',
      'lines5': 'THANK YOU FOR YOUR ORDER!',
      'lines6': 'Points earned will be credited to your LP Club account within 1 business day.',
      'order-no': 'ORDER NO.',
      'past-order': 'Order History',
      'pick-up-now': 'PRESS HERE TO PICK UP',
      'show-more': 'Show More',
      'completed-order': 'Completed Order',
      'pick-up-no': 'Pickup No.',
      'customer-hotline': 'Please call customer hotline for order status',
      'no-order': 'You have not placed an order yet',
      'order-cancelled': 'Order Cancelled'
    },
    'multi-order-detail': {
      'scan-hint': 'Please proceed to the designated restaurant to pick up your order. If you have not picked up your order at the selected time, it will be cancelled and no refund',
      'ref-nubmer': 'POS Reference Number',
      'dining-method': 'Dining Method'
    },
    'terms-conditions': {
      'cancel': 'Cancel',
      'confirm': 'I agree to the Terms & Conditions and Privacy Policy.',
      'navbarTitle': 'Terms & Conditions',
      'offlineHint': 'Please connect to the network and try again',
      'receive-brand': 'I consent to receive offers and promotions from {{name}}',
      'receive-brandPartner': 'I consent to receive offers and promotions from {{name}}\'s affiliated partner',
      'receive-order-place': 'I consent to receive offers and promotions from Order.place',
      'Reload': 'Reload',
      'agree-text': 'I consent to receive promotional offers and marketing information from {{name}}'
    },
    'variation': {
      'confirm': 'Confirm',
      'title': 'Please Select'
    },
    'verify-registration': {
      'complete': 'COMPLETE',
      'count-down-message-one': 'Re-Send code in ',
      'count-down-message-two': ' seconds',
      'input-code-message-one': 'Please enter the 6-digit code sent to',
      'input-code-message-two': '',
      'invalid-email': 'Please input valid email',
      'invalid-phone': 'Please input valid phone number',
      'phone-receive-code-message': 'Enter your phone number and we will send a 6-digit code via SMS to you',
      're-send-code-message': 'Re-Send code',
      'redirect-message': 'Redirect to registration’s page',
      'submit': 'Submit',
      'verification-message': 'Verification Successful'
    },
    'verify-table-code': {
      'confirm-button': 'CONFIRM',
      'enter-message': 'Enter verification code',
      'enter-message-sub': 'And the saved items will be placed in your cart',
      'wrong-code-message': 'Incorrect Code, please try again'
    },
    'virtual-scroll-category-list': {
      'cart': 'VIEW CART'
    }
  },
  'payments': {
    'alipay': 'Alipay',
    'alipayCN': 'AlipayCN',
    'alipayHK': 'AlipayHK',
    'androidpay': 'Google Pay',
    'applepay': 'Apple Pay',
    'cash': 'Pay Cash',
    'creditcard': 'Credit Card',
    'noGooglePay': 'Google Pay is not available on this device. Please select other payment methods.',
    'oepay': 'Octopus',
    'wechat': 'WeChat Pay'
  },
  'status': {
    'byod': {
      'cancelled': 'Cancelled',
      'completed': 'Completed',
      'paid': 'Paid',
      'pending': 'Pending',
      'ready': 'Ready',
      'received': 'Processing',
      'refunded': 'Refunded',
      'rejected': 'Rejected',
      'sending': 'Sending'
    },
    'delivery': {
      'cancelled': 'Cancelled',
      'completed': 'Completed',
      'paid': 'Paid',
      'pending': 'Pending',
      'ready': 'Ready',
      'received': 'Processing',
      'refunded': 'Refunded',
      'rejected': 'Rejected',
      'sending': 'Sending'
    },
    'dinein': {
      'cancelled': 'Cancelled',
      'completed': 'Completed',
      'failed': 'Failed',
      'paid': 'Paid',
      'pending': 'Pending',
      'ready': 'Ready',
      'received': 'Processing',
      'refunded': 'Refunded',
      'rejected': 'Rejected',
      'sending': 'Sending'
    },
    'kiosk': {
      'cancelled': 'Cancelled',
      'completed': 'Completed',
      'paid': 'Paid',
      'pending': 'Pending',
      'ready': 'Ready',
      'received': 'Processing',
      'refunded': 'Refunded',
      'rejected': 'Rejected',
      'sending': 'Sending'
    },
    'prekiosk': {
      'cancelled': 'Cancelled',
      'completed': 'Completed',
      'paid': 'Paid',
      'pending': 'Pending',
      'ready': 'Ready',
      'received': 'Processing',
      'refunded': 'Refunded',
      'rejected': 'Rejected',
      'sending': 'Sending'
    },
    'takeaway': {
      'cancelled': 'Cancelled',
      'completed': 'Completed',
      'failed': 'Failed',
      'paid': 'Paid',
      'pending': 'Pending',
      'ready': 'Ready',
      'received': 'Processing',
      'refunded': 'Refunded',
      'rejected': 'Rejected',
      'sending': 'Sending'
    },
    'hkaa': {
      'processing': 'Processing'
    }
  },
  'tags': {
    'preorder': 'Preorder',
    'queue': 'Queue',
    'reservation': 'Reservation'
  },
  'types': {
    'Brand': 'Brand',
    'ErrorMessage': 'ErrorMessage',
    'File': 'File',
    'Group': 'Group',
    'Member': 'Member',
    'Store': 'Store'
  }
};
