export function ZH_CN_JSON(): any {
  return zh_cn;
}
const zh_cn = {
  'buttons': {
    'cancel': '取消',
    'confirm': '确认',
    'continue': '继续',
    'lang-cancel': 'Cancel',
    'lang-ok': 'OK',
    'language': '语言',
    'no': '否',
    'ok': '确认',
    'yes': '是'
  },
  'dialog': {
    'select-table': {
      'invalid-input': '输入不正确'
    }
  },
  'drinks': {
    'cold-drink': '冻饮',
    'hot-drink': '热饮',
    'soup': '汤',
    'special-drink': '特饮'
  },
  'global': {
    'HK': '香港',
    'add-toast-msg': '已加入订单',
    'add-reward-msg': '已加入獎賞',
    'additional-msg-hkday': '请于完成付款后的{{time}}分钟内到{{store}}({{branch}}分店)领取',
    'back': '返回',
    'breakfast-close': '早市手机订餐服务暂停，请稍后再试。',
    'checkout': '查看购物车',
    'checkout-pay-later': '確定',
    'dinner-close': '晚市手机订餐服务暂停。',
    'done': '完成',
    'logout-remind': '登出会清空你的订单,你确定吗?',
    'eat-now': '即时',
    'female': '女性',
    'home': '首页',
    'item-added': '产品已加入',
    'items': '项',
    'kiosk-offline-message': '手机订餐服务暂时停止，请稍后再试。',
    'lang': '繁体中文',
    'lunch-close': '午市手机订餐服务暂停，请稍后再试。',
    'male': '男性',
    'max-delivery-message': '外送最高消费是',
    'max-item-message': '此店食品数量已到上限',
    'min-delivery-message': '外送最低消费是',
    'minutes': '分钟',
    'minutes-later': '分钟后',
    'next': '下一步',
    'next-page': '下一页',
    'no-menu': '这时段没有食品提供',
    'no-of-items': '款食品',
    'no-service-message': '手机订餐服务暂停。',
    'no-store-message': '暂时未有分店营业',
    'order-first': '请先点选食品',
    'order-warning-msg': '请先点选食品',
    'other': '不提供',
    'payment-method': '选择付款方式',
    'pick-up-charge': '外卖收费',
    'pick-up-time-invalid': '提取时间已过。请重选提取时间。',
    'please-select': '请选择',
    'please-select-alert-title': '选择提取时间',
    'please-select-max': '最多选择',
    'powered-by': 'Powered by Aigens',
    'previous': '上一页',
    'quantity': '数量',
    'total-quantity': '总数量',
    'ready-in': '制作时间',
    'select': '选择',
    'step1': '第',
    'step2': '步',
    'store-closed-message': '已关店。',
    'table-warning-msg': '请输入正确台号',
    'tea-time-close': '茶市手机订餐服务暂停，请稍后再试。',
    'to-start': '开始点餐',
    'up': '起',
    'wrong-email-message': '请输入正确的电邮地址',
    'wrong-phone-message': '请输入正确的手机号码',
    'wrong-first-name-message': '请输入名字',
    'wrong-last-name-message': '请输入姓氏',
    'wrong-time': '网站已逾时，请重新载入网页。 (或手机时间不正确，请重新设定时间)',
    'mr': '先生',
    'mrs': '太太',
    'ms': '女士',
    'miss': '小姐',
    'customerName': '名称',
    'cardType': '卡类型',
    'food-time-out': '所选的食物已超时, 请重新挑选',
    'timeZone-error': '您的手机时区设置不是香港标准时间(GMT+8)，请重新设置后再提交订单。',
    'select-restaurant': '选择餐厅',
    'hkaaTitle': '香港国际机场'

  },
  'pages': {
    'coupon-list': {
      'select-coupon': '选择现金券',
      'coupon-reminder': '使用现金券即表示您已阅读并同意现金券的条款和细则',
      'no-coupon-reminder': '您没有任何现金券。',
      'tnc': '条款和细则',
      'qty': '总数量:',
      'valid-until': '有效期至:',
      'coupon': '现金券'
    },
    'base': {
      'missing-tnc': '请添加条款',
      'order-time-out': '订单超时',
      'time-out-message': '所选食品将被删除'
    },
    'openrice': {
      'title': '称谓',
      'CCCode': '国家 / 地区代码',
      'visa': 'Visa',
      'master': '万事达',
      'amex': '美国运通',
      'union': '银联',
      'out-of-stock': '食品已售罄',
      'marketing-explain': '立即订阅最新资料，我们会第一时间为您送上独家的推广及优惠!',
      'marketing': '我希望收到香港国际机场的优惠券､推广信息及最新消息，并同意机场管理局使用我的个人资料作此用途。',
      'no-credit-card': '请选择卡类型'
    },
    'page-byod-brand-splash': {
      'faq': '常见问题'
    },
    'profileModal': {
      'member-profile': '会员详情',
      'member-number': '会员号码',
      'name': '姓名',
      'mobile': '手机号码',
      'email': '电邮地址',
      'language': '语言',
      'birth': '生日',
      'area': '居住地区',
      'preferred-communication-language': '偏好语言',
      'company': '公司名称',
      'companySubTitle': '机场管理员资料',
      'referral-code': '推荐码',
      'certificate-number': '机场禁区通行证／机组乘员证号码',
      'expiry-date': '到期日',
      'staff-tatus': '机场员工状态',
      'approved': '成功登记',
      'toBeExpired': '已过期 (请尽快更新)',
      'pending': '正在审核',
      'declined': '登记失败',
      'expired': '已过期'
    },
    'brand-store-list': {
      'alert-title': '所选食品将被删除，\n你确定吗?',
      'close-message': '暂停营业',
      'dinein-only': '只限堂食',
      'opening-hours': '营业时间',
      'takeaway-only': '只限外卖',
      'alert-title-hk': '离开点餐？',
      'alert-subtitle-hk': '所选食品将被删除，\n你确定吗?',
      'alert-nobtn-hk': '取消',
      'alert-removebtn-hk': '删除'
    },
    'byod-summary': {
      'discount': '折扣',
      'no': '不了',
      'orderFailure': '请与我们职员联络',
      'paidWGpay': '使用Google Pay付款',
      'payHere': '前往付款',
      'printer-disconnect-message': '打印机离线',
      'qrcode-message-one': '请往相关柜台扫瞄以下取餐QR Code。',
      'qrcode-message-two': '请于订单付款成功后的15分钟内领取您的食物, 逾期作废并不作退款',
      'reverse-order-button': '倒序订单',
      'service-charge': 'Prices are subject to 10% service charge and prevailing GST.',
      'subtitle': '订单',
      'survey1': '你好👋, 用餐愉快嗎?',
      'survey2': '告訴我們你的體驗, 好讓我們下次為你提供更好的服務',
      'table': '台',
      'tables': '枱号',
      'title': '订单纪录',
      'yes': '好'
    },
    'cart': {
      'member-send-order': '使用会员身份结账以获取积分或优惠',
      'guest-send-order': '确定以访客身份结账',
      'add': '加单',
      'apply': '使用',
      'changeReward': '更改',
      'confirm': '订单一经确定，恕不能更改或取消。',
      'continue-order': '继续点餐',
      'delete': '删除',
      'delivery-message': '製作时间大约 ',
      'delivery-time': '送货时间',
      'discount-code-fail': '折扣碼無效',
      'discount-code-ok': '成功使用折扣碼',
      'done': '完成',
      'edit': '修改',
      'edit-cap': '修改',
      'input-discount-code': '输入折扣码',
      'last-update-time': '最后更新时间',
      'miyo': 'Make It Your Own',
      'myReward': '我的奖赏!',
      'order-more': '继续点餐',
      'ordered-time': '下单时间 : ',
      'payment-no-supported': '你当前的浏览器不支持Payment, 请更換 Safari / Chrome',
      'pickUp-time': '取餐时间',
      'please-select': '请选择取餐时间',
      'save-order': '储存订单',
      'selected-1': '已选 ',
      'selected-2': ' 项',
      'selectedReward': '已选用以下奖赏',
      'selectReward': '有可用奖赏！',
      'send-order': '确定结帐',
      'send-order-pay-later': '确定送出订单',
      'special-request': '特別要求',
      'subtotal': '小计',
      'tax-included': ' (含稅)',
      'time-limit-1': '我们正努力准备你的订单, 请于',
      'time-limit-2': '后再试',
      'title': '订单资料',
      'total': '总计',
      'up-selling-message': '你或许有兴趣...',
      'use-reward': 'Hey!想今天就使用你的优惠吗？',
      'useReard': '使用奖赏!',
      'wechat-missing-openid': '缺少OpenID, 请用微信扫描二维码重新进入',
      'wechat-no-supported': '想用微信支付? 请用微信扫描二维码重新进入',
      'your-order': '已點選食品',
      'pickup-now': '现在',
      'no-payment-error': '系统错误，找不到付款方法, 请联络柜台',
      'process-to-payment': '选择付款方式'

    },
    'credit-card-payment': {
      'credit-card': '信用卡资料',
      'credit-card-info': '信用卡资料:',
      'credit-card-number': '信用卡號碼',
      'cvv': '安全碼',
      'month': '月份',
      'receiver-info': '持卡人资料',
      'year': '年份'
    },
    'app-DineinOrTakeawayPage': {
      'nextDay': '翌日',
      'multi-orders-suggestion': '您可同时选择多间餐厅美食'
    },
    'freeGift': {
      'checkNewReward': '查看你的奖赏',
      'rewardUnlocked': '已解锁新的奖赏!'
    },
    'item-grid': {
      'add': '加至订单',
      'order': '下单',
      'soldout': '售罄'
    },
    'item-select': {
      'chooseAtMost': '请选最多{{max}}项',
      'chooseTo': '请选{{min}} - {{max}}项',
      'chooseUp': '请选至少{{min}}项',
      'cold-drink': '冻饮',
      'confirm': '确认',
      'hot-drink': '热饮',
      'no-need': '不需要',
      'optional': '加配项目',
      'others-item': '其他',
      'select-exact-no-of-items': '* 请选择{count}项食品',
      'select-items': '请选择{{count}}项',
      'select-no-of-items': '* 请选择{min}至{max}项食品',
      'soft-drink': '汽水',
      'soup': '汤',
      'special-drink': '特饮'
    },
    'login': {
      'createAcct': '创建帐号',
      'email': '电邮',
      'incomplete-profile': '请完成资料',
      'label-title-1': '使用您的',
      'label-title-2': '登录',
      'login': '登入',
      'memberId': '会员号码',
      'no-account': '没有帐户?',
      'password': '密码',
      'passwordEmpty': '请输入你的密码',
      'phone': '手机号码',
      'register': '注册',
      'reset-password': '忘记密码',
      'social-media-title': '社交媒体登录',
      'storellet-title': '使用您的手机号码登录',
      'title': '使用您的电邮/手机号码登录',
      'username': '帐号',
      'username-placeholder': '电邮/手机号码(包区号)',
      'usernameEmpty': '请输入你的帐号',
      'reset-password-success': '重设密码成功'

    },
    'member-reward': {
      'apply-coupon': '在付款处使用优惠券',
      'contunueOrdering': '继续点餐',
      'how-to-use': '如何使用',
      'logged': '你已成功登入',
      'points-accu': '消费积分',
      'profile': '用户资料',
      'recent-promotion': '最新推广',
      'redeem': '立刻使用',
      'reward-available': '可用奖赏',
      'valid-from': '有效期',
      'empty-mem': '即将有更多优惠',
      'stamps': '会员印花',
      'stamp-rule': '每惠顾一碗拉面将获得一个印花'
    },
    'menu-list': {
      'menu-fab': 'menu-zh2',
      'title': '餐单管理'
    },
    'modifier-item-select': {
      'require': '必选',
      'title': '特別要求',
      'modify': '特別要求'
    },
    'multi-order-history': {
      'refresh': '刷新...'
    },
    'multi-order-cart-page': {
      'order-countdown': '订单将在 {{minutes}}分钟{{seconds}}秒 内到期'
    },
    'order-status': {
      'fail-message-one': '下单失败',
      'fail-message-two': '请联络我们的店员',
      'fail-notification': '订单无法传送，交易已取消',
      'sending-to-pos-notification': '订单传送中',
      'success-message-one': '下单成功',
      'success-message-three': '当订单已准备完成，请前往相关餐厅取餐。',
      'success-message-two': '您可在「我的订单」查看订单状态',
      'call': '你也可以致电顾客服务热线 ',
      'call-message': ' 查询订单状态 (服务时间：09:00 to 01:00)'
    },
    'order-status-byod': {
      'back': '订单记录',
      'back-to-home': '返回首页',
      'message-two': '请通知店员协助扫描QR Code付款',
      'pending-1': '抱歉,您的订单暂时未能传送!',
      'pending-2': '您可以在',
      'pending-3': '订单页面',
      'pending-4': '查看您的订单',
      'received-1': '您的订单已成功传送!',
      'received-2': '您可以在',
      'received-3': '订单页面',
      'received-4': '查看您的订单',
      'sending-1': '请稍候!',
      'sending-2': '已成功提交订单',
      'sending-3': '正等候回应中',
      'summary': '订单',
      'title': '订单状态'
    },
    'order-status-prekiosk': {
      'alert-message-one': '请于',
      'alert-message-two': '前往所选定分店的自助售票机付款系统及取单。若逾时未取，请阁下重新输入订单，谢谢！',
      'alert-title': '成功订餐!',
      'back': '返回首页',
      'expire-time': 'Expire Time',
      'items': ' item(s)',
      'message-four': '前到自助机前扫瞄QR码',
      'message-one': '您的订单已成功传送',
      'message-three': '请在',
      'message-two': '请于指定自助机前扫瞄QR码付款',
      'method': 'Method',
      'order-detail': '订单资料',
      'order-grand-total': '总计',
      'store': 'Place',
      'store-address': '地址'
    },
    'payment': {
      'alipay-check-out': '前往支付宝',
      'card-number': '信用卡号码',
      'check-out': '结账',
      'email': '电邮',
      'email-optional': '电邮 (选填)',
      'email-required': '电邮 (必填)',
      'expires': '到期日',
      'message-one': '付款完成后，您将收到一封确认电邮',
      'message-two': '你亦可透过短信中链结查看订单状况',
      'mpgs-error': '信用卡号码无效',
      'my-card': '我的信用卡',
      'phone': '电话号码',
      'phone-optional': '电话号码 (选填)',
      'phone-required': '电话号码 (必填)',
      'please-enter-email': '请输入电邮',
      'security-message-1': '以上安全加密付款服務由',
      'security-message-2': '提供',
      'special-message': '此网上商户在香港特别行政区运作',
      'title': '付款',
      'wechat-check-out': '前往微信支付',
      'wechat-pay-total': '支付金额',
      'no-refund-after-payment': '付款后恕不退款',
      'alipay-wechat-reminder': '使用支付宝 / 微信支付必须先完成实名认证及开通境外支付'
    },
    'pre-order': {
      'item-saved': '以下项目已成功加入至已存订单',
      'order-saved': '订单已存',
      'page1': './assets/images/preOrder/preIns1_zh.jpg',
      'page2': './assets/images/preOrder/preIns2_zh.jpg',
      'saved-order-found': '找到已存订单',
      'saved-order-msg': '已成功将订单导入, 随意点击后即可落单',
      'seated-action': '已入座? 按此输入提示号码',
      'go-cart': '訂單總結'
    },
    'profile': {
      'alert-title': '你确定要登出吗？',
      'complete-profile': '请完成你的简介',
      'date-of-birth': '出生日期',
      'edit': '编辑',
      'email': '电子邮件',
      'first-name': '名字',
      'gender': '性別',
      'last-name': '姓氏',
      'log-out': '登出',
      'phone': '电话',
      'profile': '个人资料',
      'profile-updated': '个人资料已更新',
      'reset-password': '重设密码',
      'reset-password-success': '重设密码成功',
      'save': '储存'
    },
    'redirect': {
      'open-wechat': '打开微信',
      'use-wechat': '请使用微信客户端打开链接'
    },
    'registration': {
      'alert-title': '你确定要离开吗？',
      'confirm-password': '确认密码',
      'create-message': '创建帐号',
      'DOB': '出生日期',
      'gender': '性別',
      'email': '电邮',
      'message': '',
      'name': '姓名',
      'first-name': '名字',
      'last-name': '姓氏',
      'password': '密码',
      'password-rule': '至少8位字符，至少一个子母',
      'phone': '手机号码',
      'title': '注册',
      'required': '必填'
    },
    'reset-password': {
      'confirm-password': '确认密码',
      'count-down-message-one': '在',
      'count-down-message-two': '秒后可以重新传送验证码',
      'email': '电邮',
      'email-receive-code-message': '请输入电邮用以接收验证码',
      'input-code-message-one': '请输入从',
      'input-code-message-two': '接收的4位数字验证码',
      'old-password': '旧密码',
      'password': '密码',
      'phone': '手机',
      'phone-receive-code-message': '请输入手机号码用以接收短讯',
      're-send-code-message': '重新传送验证码',
      'receive-email-message': '请输入电邮地址以接收重置密碼验证',
      'receive-message': '请输入电邮地址或手机号码以接收重置密碼验证',
      'reset-password-message': '设置新密码',
      'submit': '提交'
    },
    'reward-list': {
      'conditions': '条件',
      'my-coupons': '我的优惠券',
      'terms': '条款'
    },
    'saved-order-list': {
      'direction-text': '入座后请选择其中一种方式以完成点餐',
      'input-code': '输入开台码',
      'scan-code': '扫瞄QR Code'
    },
    'scan-qrcode': {
      'error-message-default': '无法读取, 请重新扫瞄',
      'error-message-nocamera': '无法开启扫瞄功能',
      'error-message-notcompatible': '此浏览器不支援扫瞄功能, 请手动输入台号',
      'error-message-timeout': '扫瞄时间逾时!',
      'loading-message-reading': '读取台号中',
      'loading-message-scanning': '扫瞄中...',
      'loading-message-starting': '开启镜头中'
    },
    'search-item': {
      'recent-search': '最近搜寻',
      'search': '搜寻食品',
      'show': '顯示',
      'results': '項結果',
      'search-result': '搜尋結果'
    },
    'select-table': {
      'please-select': '请选择',
      'room': '房间',
      'select-location': '选择位置',
      'table': '台号',
      'table-number': '台号'
    },
    'set-select': {
      'click-to-start': '按此处开始点餐吧',
      'confirm-order': '加入订单',
      'edit-order': '确认修改',
      'mandatory': '请选择',
      'miyo': 'Have it your way',
      'no-need': '不需要',
      'not-yet-completed': '尚未选完',
      'optional': '可选',
      'picked': '已选',
      'please-select': '请选择',
      'select-error': '请点选所有选项',
      'serve-with': '跟配',
      'special-request': '特別要求',
      'complete-first': '先选好随餐食品'
    },
    'splash': {
      'welcome': '欢迎',
      'address': '店铺位置',
      'continue': '继续下单',
      'reorder': '您有未完成的订单，要继续下单吗？',
      'continue-ask': '订单已被提交。需要加单？',
      'date': '- 日期 -',
      'dine-in': '堂食',
      'enterPasscode': '请输入通行码',
      'enterPasscode-hkbh': '*你可从手带上找到编号',
      'enterPasscode-hkbh1': '请如图示, ',
      'enterPasscode-hkbh2': '输入你手带上的编码(连同引号)',
      'enterPasscode-hkbh3': '例如A301-1 或 A101',
      'errorPasscode': '通行码有误, 请再输入',
      'general-table-locked': '点餐时间已过',
      'login': '登入',
      'member': '会员',
      'mobile-only': '只接受手机下单',
      'prompt-dine-mode': '请选择',
      'session': '- 交易号码 -',
      'staff': '- 员工 -',
      'start': '点击开始下单',
      'store-close': '店铺已关闭',
      'summary': '我的订单',
      'table': '台号',
      'table-locked': '台号被另一部手机锁定',
      'input-seats': '输入用餐人数',
      'input-seats-subtitle': '如之后需更改人数,请联络职员',
      'seats-placeholder': '1-20',
      'takeout': '外卖',
      'no-cat-error': '点餐服务暂时停止，请稍后再试。',
      'food-order-service': '食物订购服务'
    },
    'summary-list': {
      "payment-not-confirmed": "订单未完成",
      'current-order': '当前订单',
      'detail': '详细资料',
      'lines1': '现在取餐？',
      'lines2': '滑动确认取餐前请向店员出示此页面，否则当已取餐论。',
      'lines3': '*请于30分钟内取餐，逾时订单作废。',
      'lines4': '取餐成功',
      'lines5': '多谢惠顾!',
      'lines6': '是次消费所赚取的积分将于下一个工作天内存入你的 LP Club 帐户',
      'order-no': '订单号码',
      'past-order': '过去订单',
      'pick-up-now': '按此取餐',
      'show-more': '更多',
      'completed-order': '已完成订单',
      'pick-up-no': '取餐编号',
      'customer-hotline': '请致电服务热线查询订单状态',
      'no-order': '您尚未下单',
      'order-cancelled': '订单取消'
    },
    'multi-order-detail': {
      'scan-hint': '请前往指定餐厅领取您的订单, 如果您未在指定时间领取订单,订单将被取消且不予退款。',
      'ref-nubmer': 'POS 参考号码',
      'dining-method': '用餐模式'
    },
    'terms-conditions': {
      'agree-text': '我同意接收来自{{name}}的推广和市场消息',
      'cancel': '取消',
      'confirm': '本人同意并接受条款及细则及私隐政策',
      'navbarTitle': '使用条款',
      'offlineHint': '请连接网络然后重试',
      'receive-brand': '我同意接收{{name}}及的优惠及推广讯息',
      'receive-brandPartner': '我同意接收{{name}}的商业伙伴的优惠及推广讯息',
      'receive-order-place': '我同意接收Order.place的优惠及推广讯息',
      'Reload': '重新载入'
    },
    'variation': {
      'confirm': '确认',
      'title': '请选择'
    },
    'verify-registration': {
      'complete': '完成',
      'count-down-message-one': '在',
      'count-down-message-two': '秒后可以重新传送验证码',
      'input-code-message-one': '请输入从',
      'input-code-message-two': '接收的6位数字验证码',
      'invalid-email': '请输入正确的电邮',
      'invalid-phone': '请输入正确的电话号码',
      'phone-receive-code-message': '请输入手机号码用以接收短讯',
      're-send-code-message': '重新传送验证码',
      'redirect-message': '进入注册页面',
      'submit': '提交',
      'verification-message': '验证成功'
    },
    'verify-table-code': {
      'confirm-button': '确定',
      'enter-message': '请输入号码',
      'enter-message-sub': '我們會將你已儲存的餐點放入訂單',
      'wrong-code-message': '号码错误，请重试'
    },
    'virtual-scroll-category-list': {
      'cart': '购物车'
    }
  },
  'payments': {
    'alipay': '支付宝',
    'alipayCN': '支付宝(内地)',
    'alipayHK': '支付宝(香港)',
    'androidpay': 'Google Pay',
    'applepay': 'Apple Pay',
    'cash': '现金',
    'creditcard': '信用卡',
    'noGooglePay': 'Google Pay 暂时不可用。请选择其它付款方式。',
    'oepay': '八达通',
    'wechat': '微信支付'
  },
  'status': {
    'byod': {
      'cancelled': '已取消',
      'completed': '完成',
      'paid': '已付款',
      'pending': '等待中',
      'ready': '可取餐',
      'received': '制作中',
      'refunded': '已退款',
      'rejected': '发送失败',
      'sending': '发送中'
    },
    'delivery': {
      'cancelled': '已取消',
      'completed': '完成',
      'paid': '已付款',
      'pending': '等待中',
      'ready': '可取餐',
      'received': '制作中',
      'refunded': '已退款',
      'rejected': '发送失败',
      'sending': '发送中'
    },
    'dinein': {
      'cancelled': '已取消',
      'completed': '完成',
      'failed': '失败',
      'paid': '已付款',
      'pending': '等待中',
      'ready': '可取餐',
      'received': '制作中',
      'refunded': '已退款',
      'rejected': '发送失败',
      'sending': '发送中'
    },
    'kiosk': {
      'cancelled': '已取消',
      'completed': '完成',
      'paid': '已付款',
      'pending': '等待中',
      'ready': '可取餐',
      'received': '制作中',
      'refunded': '已退款',
      'rejected': '发送失败',
      'sending': '发送中'
    },
    'prekiosk': {
      'cancelled': '已取消',
      'completed': '完成',
      'paid': '已付款',
      'pending': '等待中',
      'ready': '可取餐',
      'received': '制作中',
      'refunded': '已退款',
      'rejected': '发送失败',
      'sending': '发送中'
    },
    'takeaway': {
      'cancelled': '已取消',
      'completed': '完成',
      'failed': '失败',
      'paid': '已付款',
      'pending': '等待中',
      'ready': '可取餐',
      'received': '制作中',
      'refunded': '已退款',
      'rejected': '发送失败',
      'sending': '发送中'
    },
    'hkaa': {
      'processing': '处理中'
    }
  },
  'tags': {
    'preorder': '预购',
    'queue': '排队',
    'reservation': '订座'
  },
  'types': {
    'Brand': '品牌',
    'ErrorMessage': '错误信息',
    'File': '文件',
    'Group': '集团',
    'Member': '会员',
    'Store': '商店'
  }
};
